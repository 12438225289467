import { useState, useEffect } from 'react'

export function usePoolInfo() {
  const poolInfoStatsInitialState = {
    epoch: 0,
    totalShurikenCount: 0,
    solutionsCount: 0,
    totalHashrate: 0,
  }
    
  const [poolInfoStats, setPoolInfoStats] = useState(poolInfoStatsInitialState)
  
  useEffect(() => {
    // Fetch the pool stats from the API using await and async
    async function fetchPoolStats() {
      // get .env variable
      const POOL_API_URL = process.env.REACT_APP_POOL_API_URL
      // Construct the pool stats API endpoint
      const poolStatsApi = `${POOL_API_URL}/stats/epoch`
      // Fetch the pool stats from the API
      const response = await fetch(poolStatsApi)
      const data = await response.json()
      setPoolInfoStats(data)
    }
    fetchPoolStats()
  }, [])

  const { epoch, totalShurikenCount, solutionsCount, totalHashrate } = poolInfoStats || poolInfoStatsInitialState

  return {
    epoch,
    totalShurikenCount, 
    solutionsCount,
    totalHashrate,
  }
}
