import React from 'react';
import { Box } from '@chakra-ui/react';

const BackgroundImage = ({ children }) => {
  return (
    <>
      <Box
        pos="fixed"
        top="0"
        left="0"
        width="full"
        height="full"
        backgroundImage="url('bg.webp')"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundAttachment="fixed"
        zIndex="-1"
        _before={{
          content: `""`,
          position: "absolute",
          top: 0,
          left: 0,
          width: "full",
          height: "full",
          backdropFilter: "blur(2px)", // Adjust the blur intensity as needed
        }}
      />
      {children}
    </>
  );
};

export default BackgroundImage;
