import React from 'react'
import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react'
// Custom
import { codeTheme } from './code'

const customTheme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  components: { 
    Code: codeTheme 
  },
  styles: {
    global: {
      // Here you can add global styles
      // 'html, body': {
      //   backgroundImage: "url('bg.jpg')",
      //   backgroundSize: 'cover',
      //   backgroundRepeat: 'no-repeat',
      //   backgroundAttachment: 'fixed',
      // },
    },
  },
});

const toastOptions = {
  defaultOptions: {
    position: 'top-right',
    duration: 5000,
    isClosable: true,
  },
};

export function UIProvider({ children }) {
  return (
    <>
      <ColorModeScript initialColorMode={customTheme.config.initialColorMode} />
      <ChakraProvider theme={customTheme} toastOptions={toastOptions}>
        {children}
      </ChakraProvider>
    </>
  );
}
