import React from 'react'
import { 
  useColorModeValue, Container, Stack, Flex, Image, Button, 
  Text, Box, Heading, createIcon,
} from '@chakra-ui/react'
import { FaDiscord, FaTwitter } from 'react-icons/fa'
import InstallGuide from '../landing/install-guide';

/* eslint-disable import/no-default-export */
export default function SetupPage() {  
  return (<>
    <Container maxW={'7xl'}>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: 'column', md: 'row' }}>
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
            <Text
              as={'span'}
              position={'relative'}
              _after={{
                content: "''",
                width: 'full',
                height: '30%',
                position: 'absolute',
                bottom: 1,
                left: 0,
                bgGradient: 'linear(to-l, #960404, #D72525)',
                zIndex: -1,
              }}>
              Qubic AI Training
            </Text>
            <br />
            <Text as="span" bgGradient="linear(to-l, #960404, #D72525)" bgClip="text">
              Setup made easy!
            </Text>
          </Heading>
          <Text>
            Follow these simple steps to get started with Qubic Ninja and start mining Shurikens today!
          </Text>
          <Stack spacing={{ base: 4, sm: 6 }} direction={{ base: 'column', sm: 'row' }}>            
            <Button as="a" href="#windows"
              rounded={'full'} size={'lg'} px={6}
              colorScheme={'red'} bgGradient={'linear(to-l, #960404, #D72525)'}
              color={'white'} leftIcon={<ShurikensIcon color={'gray.300'} />}>
              Install Ninja Miner (Windows)
            </Button>
            <Button as="a" href="#linux"
              rounded={'full'} size={'lg'} px={6}
              colorScheme={'red'} bgGradient={'linear(to-l, #960404, #D72525)'}
              color={'white'} leftIcon={<ShurikensIcon color={'gray.300'} />}>
              Install Ninja Miner (Linux)
            </Button>
          </Stack>
        </Stack>
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}>
          <Box
            position={'relative'}
            height={'400px'}
            width={'full'}
            overflow={'hidden'}>
            <Image alt={'Qubic Ninja'} h={'100%'} src={'/ninja.svg'} mx={'auto'} display={'block'} />
          </Box>
        </Flex>
      </Stack>
    </Container>

    <InstallGuide id="install" />

    <Box mt={20} id="how-it-works">
      <Stack spacing={4} as={Container} maxW={'7xl'} textAlign={'center'}>
        <Heading fontWeight={600}>
                <Text as="span" bgGradient="linear(to-l, #960404, #D72525)" bgClip="text">
                Maximizing impact with every hash
                </Text>
              </Heading>
        <Text fontSize={{ base: 'sm', sm: 'lg' }}>
          Harness the power of our PPLNS (Pay per last N shares) Qubic mining approach
        </Text>
      </Stack>
    </Box>

    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
      mt={20}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}>
        <Image src={useColorModeValue('/logo-quad.svg', '/logo-quad.svg')} alt="qubic.ninja Logo" height={'40px'} />
        <Text>© 2024 Qubic Ninja. All rights reserved</Text>
        <Stack direction={'row'} spacing={6}>
          <Button as={'a'} leftIcon={<FaDiscord />} href={'https://discord.gg/m5GRVKaw'}> Discord </Button>
          <Button as={'a'} leftIcon={<FaTwitter />} href={'https://twitter.com/QubicNinja'}> Twitter </Button>
        </Stack>
      </Container>
    </Box>
  </>)
}

const ShurikensIcon = createIcon({
  displayName: 'ShurikensIcon',
  viewBox: '0 0 512 512',
  d: `M328.8759766,132.5421448C371.3331909,201.1544342,478.1032104,73.603653,499.8647461,16.565836c61.182312,226.6875153-128.5315552,308.9147949-135.4263916,328.8926086c7.2550049,69.3253784,51.509552,118.1947937,131.3958435,156.3851624c-99.1514587,29.8260803-238.6083679-6.4488525-316.8009644-121.7224731C143.0673523,327.0992737,30.8019505,430.3282166,13.780839,496.2008667c-34.3766518-113.4429626,2.4183283-172.5073853,35.5939369-230.9724121c38.4000206-67.671936,102.2507477-75.3491211,97.4141083-117.2668152C140.0868835,89.8777008,73.1363449,30.5032635,14.5869474,13.3414001C131.6380463-32.9872322,278.9995422,51.9401283,328.8759766,132.5421448z M303.8504028,263.709137c0-40.709198-44.3727722-66.2905884-79.6792755-45.9359894s-35.3065033,71.5173798,0.0000153,91.8719635C259.4776611,329.9997253,303.8504028,304.418335,303.8504028,263.709137z`
})