import { useState, useEffect } from 'react'

// Custom hook for managing identity IDs in local storage
function useIdentityIds() {
  const [identityIds, setIdentityIds] = useState([])

  useEffect(() => {
    // Retrieve identity IDs from local storage on hook initialization
    const ids = JSON.parse(localStorage.getItem('identityIds')) || []
    setIdentityIds(ids)
  }, [])

  const addIdentityId = (identityId) => {
    // only add the identityId if it is not already in the list
    if (identityIds.includes(identityId)) return
    // Add the new identityId to the list and store it in local storage
    const ids = new Set([identityId, ...identityIds])
    const newIds = Array.from(ids).slice(0, 10) // Keep only the 10 most recent
    localStorage.setItem('identityIds', JSON.stringify(newIds))
    setIdentityIds(newIds)
  }

  const removeIdentityId = (identityId) => {
    const newIds = identityIds.filter(id => id !== identityId)
    localStorage.setItem('identityIds', JSON.stringify(newIds))
    setIdentityIds(newIds)
  }

  return { 
    identityIds, 
    addIdentityId, 
    removeIdentityId,
  }
}

export default useIdentityIds
