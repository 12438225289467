import { useState, useEffect } from 'react'
// import { useSubscribe } from 'meteor/react-meteor-data/suspense'
// import { Tracker } from 'meteor/tracker'
// Custom
// import { Pool } from '/api/pool/pool'

export function usePoolInfo({type = ''}) {
  // useSubscribe('poolInfoByType', { type })

  const [poolInfo, setPoolInfo] = useState()  

  useEffect(() => {
    // Use Tracker to create a reactive computation
    // const computation = Tracker.autorun(() => {
      // get list of versions
      // const allPoolInfo = Pool.find({}).fetch()
      // // if no pool info found, return
      // if (!allPoolInfo || allPoolInfo.length === 0) return
      // // set the pool info if the updateAt has changed
      // if(poolInfo && poolInfo.updatedAt === allPoolInfo[0].updatedAt) return

      const allPoolInfo = {
        type: 'pool',
        currentMinerVersion: '0.0.2.0',
      }

      setPoolInfo(allPoolInfo)
    // })
    // Clean up the computation when the component unmounts or the dependencies change
    // return () => computation.stop()
  }, [type])

  return {
    poolInfo,
  }
}
