import { useState, useEffect } from 'react'
import { supabase } from '../../supabase'

export const PaymentStates = {
  PENDING: 'pending',
  CONFIRMED: 'confirmed', 
  FAILED: 'failed'
}

export function usePayouts({identityId = ''}) {
  const [payouts, setPayouts] = useState([])
  // set default sorting
  const [sort, setSort] = useState({ payout_at: 1 })

  useEffect(() => {
    async function getPayouts() {
      const query = supabase.from("payouts").select().eq('identity_id', identityId)
      // apply sort
      if (Object.keys(sort).length > 0) {
        const key = Object.keys(sort)[0]
        const value = sort[key]
        query.order(key, { ascending: value === 1 ? true : false, nullsLast: true })
      }
      const { data, error } = await query
      if (error) {
        console.error('Error fetching payouts data:', error);
        return null
      }
      setPayouts(data);
    }
    getPayouts()
  }, [identityId, sort])

  const sortPayouts = (sorting) => {
    setSort(sorting)
  }

  // Calculate count
  const count = payouts.length

  // calculate total payouts if status is confirmed
  const totalPayouts = payouts.reduce((acc, payout) => {
    if (payout.status === 'confirmed') {
      return acc + payout.amount
    }
    return acc
  }, 0)

  return {
    payouts,
    count,
    totalPayouts,
    sortPayouts,
  }
}
