import { Box } from '@chakra-ui/react'
import { Navbar } from './navbar'
import React, { useState } from 'react'
import MouseSlash from './mousetrail/mouse-slash'

export function Layout({ loggedOnly = true, children }) {
  const [mouseSlashEnabled, setMouseSlashEnabled] = useState(true)

  return (
    <>
      {mouseSlashEnabled && <MouseSlash />}
      
      <Navbar
        toggleMouseSlash={() => setMouseSlashEnabled(!mouseSlashEnabled)}
        mouseSlashEnabled={mouseSlashEnabled}
      />
      
      <Box maxW="6xl" mx="auto">
        {children}
      </Box>
      {/* <Footer /> */}
    </>
  );
}
