import React from 'react';
import { Box, Code, IconButton, useClipboard, useToast } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

export function CodeCopyClipboard({ children }) {
  const { onCopy } = useClipboard(children);
  const toast = useToast();

  const handleCopy = () => {
    onCopy();
    toast({
      description: "Copied to clipboard!",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Box position="relative" mt={5}>
      <Code p={4} w="full" overflowX="auto">
        {children}
      </Code>
      <IconButton
        icon={<CopyIcon />}
        size="sm"
        position="absolute"
        top={2}
        right={2}
        onClick={handleCopy}
        aria-label="Copy code"
        variant="outline"
      />
    </Box>
  );
}
