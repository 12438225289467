import React from 'react';
import { createRoot } from 'react-dom/client';
import { UIProvider } from './common/components/ui-provider';
import { Routes } from './routes';

document.documentElement.setAttribute('lang', 'en');

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <UIProvider>
    <Routes />
  </UIProvider>
);
