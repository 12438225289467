import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const outline = defineStyle({
  border: '2px dashed', // change the appearance of the border
  borderRadius: 5, // remove the border radius
  fontSize: 'lg', // change the font size
  fontWeight: 'semibold', // change the font weight
  padding: 4, // change the padding
  
  // let's also provide dark mode alternatives
  _dark: {
    background: 'orange.300',
    color: 'orange.800',
  }
})

export const codeTheme = defineStyleConfig({
  variants: { outline },
})