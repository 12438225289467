import React from 'react'
import { Stat, StatLabel, StatNumber, StackDivider, Stack, } from '@chakra-ui/react'
import { GiNinjaStar } from 'react-icons/gi'
// Custom
import { usePoolInfo } from './use-pool-info'

const PoolInfo = () => {
  const { epoch, totalShurikenCount, solutionsCount, totalHashrate } = usePoolInfo()

  // calculate the shuriken value based on the total shuriken count and solutions count
  const shurikenValue = totalShurikenCount > 0 ? 1 / totalShurikenCount * 100 : 0

  return (
    <Stack mt={2} 
        direction={{ base: 'column', md: 'row' }}
        divider={<StackDivider borderColor='gray.200' />
      }>
      <Stat>
        <StatLabel>Current Ninja Power</StatLabel>
        <StatNumber>{totalHashrate} it/s</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Total Shurikens Found</StatLabel>
        <StatNumber>
          <span style={{display: 'inline-block', margin: '0 5px -3px 0'}}><GiNinjaStar /></span>
          {totalShurikenCount}
        </StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Shuriken Share Value</StatLabel>
        <StatNumber>
          <span style={{display: 'inline-block', margin: '0 5px -3px 0'}}><GiNinjaStar /></span>
          {shurikenValue.toFixed(5)} %
        </StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Solutions found in Epoch {epoch}</StatLabel>
        <StatNumber>{solutionsCount}</StatNumber>
      </Stat>            
    </Stack> 
  )
}

export default PoolInfo