import React from "react"
import { Table, Thead, Tbody, Tr, Th, TableCaption, } from '@chakra-ui/react'
// Custom
import { PayoutItem } from "./payout-item"

export const ListType = {
  COMPACT: 'compact',
  EXPANDED: 'expanded'
}

export const ListLayout = {
  GRID: 'grid',
  LIST: 'list'
}

const PayoutsList = ({ payouts, listType = ListType.COMPACT }) => {
  return (
    <Table variant='simple'>
      <TableCaption>Payouts are calculated based on your contributions per epoch.</TableCaption>
      <Thead>
        <Tr>
          <Th style={{textAlign: 'center'}}>Status</Th>
          <Th>Date</Th>
          <Th>Epoch</Th>
          <Th>Amount</Th>
          {listType === ListType.EXPANDED && <>
            <Th>Shares Delivered</Th>
            <Th>Pool Stake</Th>
            <Th>Network Stake</Th>
            <Th>Message</Th>
          </>}
        </Tr>
      </Thead>
      <Tbody>
        {payouts.map(payout => (
          <PayoutItem 
            key={payout.id}
            payout={payout}
            listType={listType}
          />
        ))}
      </Tbody>
    </Table>
  )
}

export default PayoutsList