import React, { useEffect, useState } from 'react'
import { 
  Box, Button, Flex, Link, Stack, Text, useColorMode, useColorModeValue, 
  Image, HStack, Badge, Select, useBreakpointValue, IconButton, useDisclosure,
  Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, DrawerFooter, 
  Divider, FormControl, FormLabel, Switch
} from '@chakra-ui/react'
import { MoonIcon, SunIcon, HamburgerIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons'
import { FaDiscord, FaTwitter } from 'react-icons/fa'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
// Custom
import { Logout } from './logout'
import { routes } from '../../routes'
import useIdentityIds from '../hooks/use-identity-ids'
import { shortenAddress } from '../../shared'

export function Navbar({ toggleMouseSlash, mouseSlashEnabled }) {
  const { colorMode, toggleColorMode } = useColorMode()
  // const userId = false // useUserId()
  const location = useLocation()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  
  const { identityId } = useParams();
  const [identityIdParam, setIdentityIdParam] = useState(identityId)

  // Get the identityIds from local storage
  const { identityIds, removeIdentityId } = useIdentityIds()

  const menuToggleIcon = isOpen ? <CloseIcon /> : <HamburgerIcon />
  const isMobile = useBreakpointValue({ base: true, md: false })

  useEffect(() => {
    setIdentityIdParam(identityId)
  }, [identityId])

  const MouseSlashSwitch = () => (
    <FormControl display='flex' alignItems='center' w={'auto'}>
      <FormLabel htmlFor='mouse-slash' mb='0'>Fight</FormLabel>
      <Switch id='mouse-slash' colorScheme='red'
        onChange={toggleMouseSlash}
        isChecked={mouseSlashEnabled}
      />
    </FormControl>
  )

  return (
    <Box pos="sticky" top={0} zIndex={10}>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH="60px"
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle="solid"
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align="center"
      >
        <Flex flex={{ base: 1 }} justify="start">
          <Link href={routes.root}>
            <HStack>
              <Image src="/logo-quad.svg" alt="qubic.ninja Logo" h={'40px'} />
              <Text
                as="span"
                color={useColorModeValue('black', 'white')}
                fontWeight="bold"
                fontFamily="heading"
                textAlign="left"
              >
                QUBIC.NINJA
              </Text>
            </HStack>
          </Link>
        </Flex>        

        <Stack
          display={{ base: 'none', md: 'flex' }}
          flex={{ base: 1, md: 0 }}
          justify="flex-end"
          direction="row"
          spacing={6}
        >
          {/*{userId && (<>
            <Button as="a" href={routes.dashboard} variant="ghost" color="gray.500">
              Dashboard
            </Button>
          </>)}

          {!userId && location.pathname === '/signin' && (<>
            <Button as="a" href={routes.signin} variant="ghost" color="gray.500">
              Sign In
            </Button>
          </>)} */}
          
          {identityIds.length > 0 &&
          <>
            {location.pathname !== '/start' &&
              <Button as="a" href={routes.start} variant="ghost" color="gray.500">Use New ID</Button>
            }
            <Select 
              placeholder='Switch Qubic ID' width={'auto'} minWidth="200px" 
              onChange={(e) => navigate(`/dashboard/${e.target.value}`)}              
            >
              {identityIds.map(id => <option key={id} value={id}>{shortenAddress(id, 8)}</option>)}
            </Select>
          </>}

          {identityIdParam && identityIds.length === 1 && (<> 
            <HStack><Badge>ID: {shortenAddress(identityIdParam,5)}</Badge></HStack>
          </>)}

          <Button onClick={toggleColorMode}
            aria-label={colorMode === 'light' ? 'Moon Icon' : 'Sun Icon'}>
            {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
          </Button>
          
          <MouseSlashSwitch />

          <Logout />
        </Stack>

        {isMobile && (
          <IconButton
            onClick={onOpen}
            icon={menuToggleIcon}
            aria-label="Open Menu"
            size="md"
            mr={2}
          />
        )}

        <Drawer isOpen={isOpen} placement='right' onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Select your identity:</DrawerHeader>
            <DrawerBody>
              <Stack>
                {identityIds.map(id => (
                  <Flex key={id} direction={'row'} gap={2} justify={'space-between'}>
                    <Button key={id} onClick={() => {
                      onClose()
                      navigate(`/dashboard/${id}`)
                    }}>
                      {shortenAddress(id, 8)}
                    </Button>
                    <IconButton icon={<DeleteIcon />} onClick={() => {
                      removeIdentityId(id)
                    }} />
                  </Flex>
                ))}
                <Divider />
                {location.pathname !== '/start' &&
                  <Button as="a" href={routes.start} variant="ghost" color="gray.500">Use New ID</Button>
                }                
              </Stack>
            </DrawerBody>
            <DrawerFooter>
              <Stack direction={'row'} spacing={6}>
                <MouseSlashSwitch />
                <IconButton onClick={toggleColorMode}
                  aria-label={colorMode === 'light' ? 'Moon Icon' : 'Sun Icon'}
                  icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />} />
                <IconButton icon={<FaDiscord />} href={'#'} />
                <IconButton icon={<FaTwitter />}  href={'https://twitter.com/QubicNinja'} />
              </Stack>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>

      </Flex>
    </Box>    
  )
}
