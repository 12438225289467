import React, { memo } from 'react'
import { Tr, Td, Tooltip } from '@chakra-ui/react'
import { format } from 'date-fns'
import { MdPending } from 'react-icons/md'
import { LuCheckCheck } from 'react-icons/lu'

export const PayoutItem = memo(({ payout, listType }) => {  

  const { status, payout_at, epoch, amount, solutions_delivered,
    pool_stake, network_stake, message } = payout

  return (
    <Tr>
      <Td style={{textAlign: 'center'}}>
        <Tooltip label={status}>
          <span style={{display: 'inline-block'}}>{status === 'pending' ? <MdPending /> : <LuCheckCheck />}</span>
        </Tooltip>
      </Td>
      <Td>{format(payout_at, 'MM/dd/yyyy')}</Td>
      <Td>{epoch}</Td>
      <Td>{amount} QUBIC</Td>
      {listType === 'expanded' && <>
        <Td>{solutions_delivered}</Td>
        <Td>{pool_stake}</Td>
        <Td>{network_stake}</Td>
        <Td>{message}</Td>
      </>}      
    </Tr>
  )
})
