import React, { memo } from 'react'
import { 
  Tr, Td
} from '@chakra-ui/react'
import { format, formatDistanceToNow } from 'date-fns'
import { GiNinjaStar } from 'react-icons/gi'
import { ListType } from '../../payouts/components/payouts-list'

export const MinerItemList = memo(({ key, listType, miner, currentVersion }) => {  
  const { name, createdDate, lastActiveDate, version, solutions, hashrate } = miner

  return (
    <Tr key={key}>
      <Td>{name}</Td>
      <Td>{hashrate}</Td>
      <Td><span style={{display: 'inline-block', margin: '0 5px -3px 0'}}><GiNinjaStar /></span>{solutions}</Td>
      {listType === ListType.EXPANDED && <> 
        <Td>{formatDistanceToNow(lastActiveDate)}</Td>
        <Td>{version}</Td>
        <Td>{format(createdDate, 'MM/dd/yyyy')}</Td>
      </>}
    </Tr>
  )
})
