import { useState, useEffect } from 'react'
// Custom
import { getNullProps } from '../../../../shared/utils'

export function useMiners({identityId = ''}) {
  const [miners, setMiners] = useState([])  
  // for now no filter
  const [filter, setFilter] = useState({})
  const [versions, setVersions] = useState([])
  // set default sorting
  const [sort, setSort] = useState({ created_at: 1 })
  // identidy stats
  const [minersStats, setMinersStats] = useState({
    activeHashrate: 0,
    activeNinjas: 0,
    inactiveNinjas: 0,
  })

  useEffect(() => {
    // get .env variable
    const POOL_API_URL = process.env.REACT_APP_POOL_API_URL

    async function getShurikens() {
      // Construct the shuriken stats API endpoint
      const shurikenStatsApi = `${POOL_API_URL}/stats/${identityId}/shurikens`
      // Fetch the shuriken stats from the API
      const response = await fetch(shurikenStatsApi)
      const data = await response.json()
      console.log(data)
      const shurikenCount = data.reduce((acc, shuriken) => {
        // Check if the publicKey already exists in the accumulator object
        acc[shuriken.publicKey] = (acc[shuriken.publicKey] || 0) + 1
        return acc
      }, {})
      console.log(shurikenCount)
      return shurikenCount
    }

    async function getMinerVersions() {      
      // get shuriens
      const shurikens = await getShurikens()
      // Construct the ninja stats API endpoint
      const ninjaStatsApi = `${POOL_API_URL}/stats/${identityId}/ninjas`
      // Fetch the ninja stats from the API
      const response = await fetch(ninjaStatsApi)
      const data = await response.json()   
      // get list of versions
      const allVersions = data.ninjas.reduce((acc, miner) => {
        if (!acc.includes(miner.version)) acc.push(miner.version)
        return acc
      }, [])
      setVersions(allVersions)
      // get identity stats
      setMinersStats({
        activeHashrate: data.activeHashrate,
        activeNinjas: data.activeNinjas,
        inactiveNinjas: data.inactiveNinjas,
      })
    }
    getMinerVersions()
  }, [identityId])

  function dynamicSort(property, sortOrder) {
    return function (a, b) {
      // Handling null, undefined or empty string to be at the end
      if (a[property] == null || a[property] === '') return 1;
      if (b[property] == null || b[property] === '') return -1;
  
      // Normalizing string comparisons to be case-insensitive
      const valueA = (typeof a[property] === 'string') ? a[property].toUpperCase() : a[property];
      const valueB = (typeof b[property] === 'string') ? b[property].toUpperCase() : b[property];
  
      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }
      return comparison * sortOrder;
    };
  }

  useEffect(() => {
    async function getMiners() {
      // get .env variable
      const POOL_API_URL = process.env.REACT_APP_POOL_API_URL
      // Construct the pool stats API endpoint
      const ninjaStatsApi = `${POOL_API_URL}/stats/${identityId}/ninjas`
      // Fetch the pool stats from the API
      const response = await fetch(ninjaStatsApi)
      const data = await response.json()
      // assign miners list
      let minersList = data.ninjas
      // Convert the filter state into a filtering function
      const filterFunction = (item) => {
        return Object.entries(filter).every(([key, value]) => {
          return item[key] === value
        })
      }
      // Apply the filter function to the miners array
      minersList = minersList.filter(filterFunction)
      // check if sort is not empty object
      if (Object.keys(sort).length > 0) {
        // get sort key
        const key = Object.keys(sort)[0]
        // get sort value (1 or -1)
        const value = sort[key]
        // apply sort
        minersList = minersList.sort(dynamicSort(key, value))
      }
      // set solutions propterty to each miner object
      minersList = minersList.map(miner => {
        miner.solutions = 'n.a.'
        return miner
      })
      setMiners(minersList)
    }
    getMiners()
  }, [identityId, filter, sort])

  // Calculate count
  const count = miners.length

  const filterMiners = (newFilter) => {
    const currentFilter = { ...filter }
    // Check if newFilter is an key value is null    
    const nullProps = getNullProps(newFilter)
    // remove null properties from newFilter and currentFilter
    nullProps.forEach(prop => {
      delete currentFilter[prop]
      delete newFilter[prop]
    })
    setFilter({...currentFilter, ...newFilter})
  }

  const sortMiners = (sorting) => {
    setSort(sorting)
  }

  // calculate total its
  // const totalIts = miners.reduce((acc, miner) => acc + miner.its, 0)

  // calculate total solutions
  // const totalSolutions = miners.reduce((acc, miner) => acc + miner.solutions, 0)

  return {
    miners,
    count,
    // totalIts,
    // totalSolutions,
    versions,
    minersStats,
    filterMiners,
    sortMiners,
  }
}
