import React, { memo } from 'react'
import { 
  Badge, Tag, TagLeftIcon, TagLabel, Card, CardHeader, CardBody, CardFooter, Heading, 
  Stat, StatLabel, StatNumber, StatHelpText
} from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons'
import { format, formatDistanceToNow } from 'date-fns'
import { GiNinjaStar } from 'react-icons/gi'

export const MinerItemCard = memo(({ key, miner, currentVersion }) => {  
  const { name, createdDate, lastActiveDate, version, solutions, hashrate, online } = miner
  
  const getRedGreen = (a, b) => (a === b) ? 'green' : 'red'

  return (    
    <Card key={key}>
      <CardHeader>
        <Heading size='sm'>{name}</Heading>
      </CardHeader>
      <CardBody>
        <Stat>
          <StatLabel>It/sec: {hashrate}</StatLabel>
          <StatNumber>
            <span style={{display: 'inline-block', margin: '0 5px -3px 0'}}><GiNinjaStar /></span>
            Shurikens: {solutions}
          </StatNumber>
          <StatHelpText>
            {lastActiveDate &&
              <Badge colorScheme={getRedGreen(online, true)}>active: {formatDistanceToNow(lastActiveDate)}</Badge>
            }                        
          </StatHelpText>
        </Stat>
      </CardBody>
      <CardFooter>
        <Badge>created:<br/>{format(createdDate, 'MM/dd/yyyy')}</Badge>
        <Tag size='sm' variant='subtle' colorScheme={getRedGreen(version, currentVersion)} ml={5}>
          <TagLeftIcon boxSize='12px' as={InfoIcon} />
          <TagLabel>{version}</TagLabel>
        </Tag>
      </CardFooter>
    </Card>
  )
})
