import React, { useRef, useEffect } from 'react';
import { useColorModeValue } from '@chakra-ui/react';

const MouseSlash = () => {
  const pointsRef = useRef([]);
  const canvasRef = useRef(null);
  const requestRef = useRef();
  const maxFrameLife = 20; // Number of frames to keep a point alive
  const strokeStyle = useColorModeValue('rgba(255, 0, 0, opacity)', 'rgba(255, 255, 255, opacity)');
  const fillStyle = useColorModeValue('rgba(255, 0, 0, 0.2)', 'rgba(255, 255, 255, 0.2)');
  const fps = 30 // Frames per second

  useEffect(() => {
    // Capture the mouse position and activate the trail
    const handleMouseMove = (event) => {
      // Directly update the ref with the new mouse position
      pointsRef.current = pointsRef.current.slice(-9); // Keep only the last 9 points
      pointsRef.current.push({ 
        x: event.clientX, y: event.clientY, frame: 0 
      });
    };
    window.addEventListener('mousemove', handleMouseMove)
    return () => window.removeEventListener('mousemove', handleMouseMove)
  }, [])

  useEffect(() => {
    const resizeCanvas = () => {
      const canvas = canvasRef.current
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
    }
  
    window.addEventListener('resize', resizeCanvas)
    resizeCanvas() // Initial resize
  
    return () => window.removeEventListener('resize', resizeCanvas)
  }, [])

  // Start the animation loop
  useEffect(() => {
    // Animation loop to draw the trail and update points
    const animate = () => {
      // Canvas is not mounted yet, skip this frame
      if (!canvasRef.current) {        
        requestRef.current = requestAnimationFrame(animate)
        return
      }
      const canvas = canvasRef.current
      const ctx = canvas.getContext('2d')

      // Increment frame count for each point and remove old points
      pointsRef.current.forEach(point => point.frame++)
      pointsRef.current = pointsRef.current.filter(point => point.frame < maxFrameLife)

      // Always remove the oldest point to shorten the trail
      const minTrailLength = 10; // Minimum number of points before starting to remove old ones
      if (pointsRef.current.length > minTrailLength) {
        pointsRef.current.shift()
      }

      // Clear the canvas and draw the trail
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (pointsRef.current.length > 1) {
        ctx.beginPath();
        ctx.moveTo(pointsRef.current[0].x, pointsRef.current[0].y);
        for (let point of pointsRef.current) {
          const opacity = 1 - (point.frame / maxFrameLife);
          ctx.strokeStyle = strokeStyle.replace('opacity', opacity)
          ctx.lineTo(point.x, point.y);
        }
        ctx.lineWidth = 2;        
        ctx.stroke();
        // Configure the fill style
        ctx.fillStyle = fillStyle; // Semi-transparent fill
        // Fill the path with the fill style
        ctx.fill();
      }
      // Request the next animation frame force the fps
      setTimeout(() => {
        requestRef.current = requestAnimationFrame(animate);
      }, 1000 / fps);
    };
    // Start the animation loop
    requestRef.current = requestAnimationFrame(animate);
    // return the cleanup function
    return () => cancelAnimationFrame(requestRef.current);
  }, [strokeStyle, fillStyle])

  return <canvas ref={canvasRef} 
    style={{ position: 'fixed', top: 0, left: 0, pointerEvents: 'none', zIndex: 9999 }} 
  />
}

export default MouseSlash
