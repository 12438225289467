import React from 'react'
import { Button, Box, Container, Heading, Stack, Text, Flex, Card, CardBody, createIcon } from '@chakra-ui/react'
// Custom
import { CodeCopyClipboard } from '../../common/components/code-copy-clipboard'

const ShurikensIcon = createIcon({
  displayName: 'ShurikensIcon',
  viewBox: '0 0 512 512',
  d: `M328.8759766,132.5421448C371.3331909,201.1544342,478.1032104,73.603653,499.8647461,16.565836c61.182312,226.6875153-128.5315552,308.9147949-135.4263916,328.8926086c7.2550049,69.3253784,51.509552,118.1947937,131.3958435,156.3851624c-99.1514587,29.8260803-238.6083679-6.4488525-316.8009644-121.7224731C143.0673523,327.0992737,30.8019505,430.3282166,13.780839,496.2008667c-34.3766518-113.4429626,2.4183283-172.5073853,35.5939369-230.9724121c38.4000206-67.671936,102.2507477-75.3491211,97.4141083-117.2668152C140.0868835,89.8777008,73.1363449,30.5032635,14.5869474,13.3414001C131.6380463-32.9872322,278.9995422,51.9401283,328.8759766,132.5421448z M303.8504028,263.709137c0-40.709198-44.3727722-66.2905884-79.6792755-45.9359894s-35.3065033,71.5173798,0.0000153,91.8719635C259.4776611,329.9997253,303.8504028,304.418335,303.8504028,263.709137z`
})

const InstallGuide = () => {
  return (<Box mt={20} id="install">
    <Stack spacing={4} as={Container} maxW={{ base: 'full', md: '950px' }} textAlign={'center'}>
      <Heading fontWeight={600}>
        <Text as="span" bgGradient="linear(to-l, #960404, #D72525)" bgClip="text">
          Ninja Gear: Quick Start Mining
        </Text>
      </Heading>
      <Text fontSize={{ base: 'sm', sm: 'lg' }}>
        Ninja Gear is your quick-start guide to unlocking the power of your CPU in the quest for digital treasure.<br /> 
        Get ready to join the ranks of Qubic miners with a few simple steps.
      </Text>
    </Stack>

    <Stack id='windows' mt={5} spacing={4} as={Container} maxW={{ base: 'full', md: '950px' }} textAlign={'center'}>
      <Heading fontWeight={600}>
        <Text as="span" bgGradient="linear(to-l, #960404, #D72525)" bgClip="text">
          Windows Install Steps
        </Text>
      </Heading>
    </Stack>  

    <Container mt={12} maxW={'7xl'}>
      <Flex direction="row" flexWrap="wrap" justify="center" gridGap={6}>
        <Card 
              maxW={{ base: 'full', md: '950px' }}
              w={'full'}
              borderRadius="lg"
              overflow="hidden"
            >
          <CardBody>              
              <Text>Download the latest version of the Qubic Ninja Gear client for Windows and follow the steps below to get started.</Text>
              <Box m={5}>
                <Button as="a" href="https://dl.qubic.ninja/ninja/windows-latest.zip"
                  rounded={'full'} size={'lg'} px={6}
                  colorScheme={'red'} bgGradient={'linear(to-l, #960404, #D72525)'}
                  color={'white'} leftIcon={<ShurikensIcon color={'gray.300'} />}>
                  Download Ninja Miner (Windows)
                </Button>
              </Box>
              <Text>Unzip the downloaded ZIP and run the qubic-ninja executable from the command line.</Text>              
              <Text mt={5}><i>{'Usage: qubic.ninja.exe -i=<ID> -t=<THREADS> [-n=<NAME> -s=<TYPE>]'}</i></Text>
              <CodeCopyClipboard>
                qubic.ninja.exe -i=NRJISP...XSFACFYL -t=2 -n=MyNinja -s=avx512
              </CodeCopyClipboard>
          </CardBody>
        </Card>
      </Flex>
    </Container>

    <Stack id='linux' mt={5} spacing={4} as={Container} maxW={{ base: 'full', md: '950px' }} textAlign={'center'}>
      <Heading fontWeight={600}>
        <Text as="span" bgGradient="linear(to-l, #960404, #D72525)" bgClip="text">
          Linux Install Steps
        </Text>
      </Heading>
    </Stack>  

    <Container mt={12} maxW={'7xl'}>
      <Flex direction="row" flexWrap="wrap" justify="center" gridGap={6}>
        <Card 
              maxW={{ base: 'full', md: '950px' }}
              w={'full'}
              borderRadius="lg"
              overflow="hidden"
            >
          <CardBody>
            <Text>
              To run the Qubic Ninja Gear client you can use this streamlined installation guide. 
              Please consider adapting the commands to suit your directory preferences. Note that all 
              commands must be executed as the root user, or you should precede them with the sudo 
              command for proper authorization.
            </Text>              
              
              <CodeCopyClipboard>
                wget https://dl.qubic.ninja/ninja/linux-latest.tar.gz -O linux-latest.tar.gz
              </CodeCopyClipboard>
              <CodeCopyClipboard>
                tar -xzvf linux-latest.tar.gz
              </CodeCopyClipboard>
              <CodeCopyClipboard>
                chmod u+x qubic.ninja
              </CodeCopyClipboard>
              <Text mt={5}><i>{'Usage: qubic.ninja -i=<ID> -t=<THREADS> [-n=<NAME> -s=<TYPE>]'}</i></Text>
              <CodeCopyClipboard>
                ./qubic.ninja -i=NRJISP...XSFACFYL -t=2 -n=MyNinja -s=avx512
              </CodeCopyClipboard>
          </CardBody>
        </Card>
      </Flex>
    </Container>
  </Box>)
}

export default InstallGuide