import { useState, useEffect } from 'react'

export function useIdentityInfo({identityId = '', epoch = 0}) {
  const identityInfoStatsInitialState = {
    epoch: 0,
    myShurikenCount: 0,
    myNinjaCount: 0,
    myHashrate: 0
  }
    
  const [identityInfoStats, setIdentityInfoStats] = useState(identityInfoStatsInitialState)
  
  useEffect(() => {
    // Fetch the identity stats from the API using await and async
    async function fetchIdentityStats() {
      // get .env variable
      const POOL_API_URL = process.env.REACT_APP_POOL_API_URL
      // Construct the identity stats API endpoint
      const identityStatsApi = `${POOL_API_URL}/stats/epoch/${epoch}/${identityId}`
      // Fetch the pool stats from the API
      const response = await fetch(identityStatsApi)
      const data = await response.json()
      setIdentityInfoStats(data)
    }
    fetchIdentityStats()
  }, [identityId, epoch])

  return identityInfoStats
}
