import React, { useRef, useState, startTransition } from 'react'
import { 
  useColorModeValue, Select, VStack, Image, Input, Button, Card, Text, 
  CardBody, InputGroup, InputLeftElement, InputRightElement, FormControl, FormLabel,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { HiIdentification } from 'react-icons/hi2'
// Custom
import { ConfirmDialog } from '../../common/components/confirm-dialog'
import BackgroundImage from '../../common/components/background-image'
import useIdentityIds from '../../common/hooks/use-identity-ids'
import { shortenAddress } from '../../shared'
// import { supabase } from '../../common/components/supabase'
import { CodeCopyClipboard } from '../../common/components/code-copy-clipboard'

/* eslint-disable import/no-default-export */
export default function StartPage() {

  const { identityIds, addIdentityId } = useIdentityIds()

  const navigate = useNavigate()
  const identityId = useRef(null)
  const [showMinerInstruction, setShowMinerInstruction] = useState(false)

  const navigateToDashboard = async (identityId) => {
    const idCheck = true // await checkIdentityId(identityId)
    if(idCheck === false) {
      setShowMinerInstruction(true)
      return console.log('No miners found for this identityId')
    }
    // store the identityId in local storage for later use
    addIdentityId(identityId)
    // redirect to the dashboard with the identityId as a parameter
    startTransition(() => navigate('/dashboard/' + identityId))
  }
  const handleGoClick = () => navigateToDashboard(identityId.current.value)
  const handleEnterPress = (event) => event.key === 'Enter' ? navigateToDashboard(identityId.current.value) : null

  // const checkIdentityId = async (identityId) => {
  //   let { data, error, count } = await supabase
  //     .from('miners')
  //     .select('identity_id', { count: 'exact' })
  //     .eq('identity_id', identityId)
  
  //   if (error && !data) return false  
  //   // If 'count' is greater than 0, the identityId exists
  //   return count > 0
  // }

  return (
    <BackgroundImage>
      {/* <Suspense fallback={<Spinner />}> */}
        <VStack>          
          <Card mt={10}>
            <CardBody>
              <Image src={useColorModeValue('/logo.svg', '/logo-white.svg')} alt="qubic.ninja Logo"  />
              <Text align={'center'} m={5}>AI Mining the ninja way!</Text>
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <HiIdentification color='gray.300' />
                </InputLeftElement>
                <Input 
                  ref={identityId} 
                  placeholder="Your Public Qubic ID" 
                  onKeyDown={handleEnterPress}
                />
                <InputRightElement>
                  <Button onClick={handleGoClick}>GO</Button>
                </InputRightElement>
              </InputGroup>
              {identityIds.length > 0 &&
                <FormControl as='fieldset' mt={5}>
                  <FormLabel as='legend' textAlign={'center'}>Select one of your recent IDs:</FormLabel>
                  <Select placeholder='Qubic Public IDs' onChange={(e) => navigateToDashboard(e.target.value)}>
                    {identityIds.map(id => <option key={id} value={id}>{shortenAddress(id, 8)}</option>)}
                  </Select>
                </FormControl>
              }
            </CardBody>
          </Card>
        </VStack>
        
        <ConfirmDialog 
          isOpen={showMinerInstruction} onClose={() => setShowMinerInstruction(false)} 
          title='Ninja Setup' 
          body={<>
            <Text mb={5}>
              No Ninjas found for this identity.<br/> 
              Please download the miner software and run it with the following command:
            </Text>                      
            <CodeCopyClipboard>
              miner.exe {identityId?.current?.value}
            </CodeCopyClipboard>
          </>}
        />

      {/* </Suspense> */}
    </BackgroundImage>
  )
}
