import React from "react"
import { 
  Table, Thead, Tbody, Tr, Th, 
  Grid, useBreakpointValue,
} from '@chakra-ui/react'
import { nanoid } from 'nanoid'
// Custom
import { ListLayout, ListType } from "../../payouts/components/payouts-list"
import { MinerItemCard } from './miner-item-card';
import { MinerItemList } from './miner-item-list';
import { usePoolInfo } from "../../../hooks/use-pool-info";
import { PoolInfoTypes } from "../../../../shared";

const MinersList = ({miners = [], listType = ListType.COMPACT, layout = ListLayout.GRID}) => {
  // get pool info for the current pool
  const { poolInfo } = usePoolInfo({type: PoolInfoTypes.POOL_INFO})
  const { currentMinerVersion } = poolInfo || { currentMinerVersion: '' }
  // set the layout type for miners list component
  const MinerItem = ({ ...props }) => 
    (layout === ListLayout.GRID) ? <MinerItemCard { ...props } /> 
    : <MinerItemList listType={listType} { ...props } />
  
  const isMobile = useBreakpointValue({ base: true, md: false })

  const getTemplateColumns = () => {
    if (isMobile) return '1fr'
    return listType === ListType.COMPACT ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'
  }

  const Items = () => <>
    {miners.map(miner => (
      <MinerItem 
        key={nanoid()}
        miner={miner}
        currentVersion={currentMinerVersion}
      />
    ))}
  </>

  return (<>
    {layout === ListLayout.LIST && 
      <Table variant='simple'>    
        <Thead>
          <Tr>
            <Th>Alias</Th>
            <Th>It/sec</Th>
            <Th>Shurikens</Th>            
            {listType === ListType.EXPANDED && <>
              <Th>Last Active</Th>
              <Th>Version</Th>
              <Th>Created</Th>
            </>}
          </Tr>
        </Thead>
        <Tbody>
          <Items />
        </Tbody>
      </Table>
    }

    {layout === ListLayout.GRID && 
      <Grid templateColumns={getTemplateColumns()} gap={6}>
        <Items />
      </Grid>
    }
  </>)
}

export default MinersList