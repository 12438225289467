import React from 'react'
import { BrowserRouter, Route, Routes as ReactRoutes } from 'react-router-dom'
import { Layout } from './common/components/layout'
import LandingPage from './pages/landing/landing-page'
import StartPage from './pages/start/start-page'
import SignInPage from './pages/auth/sign-in-page'
import NotFoundPage from './pages/not-found/not-found-page'
import DashboardPage from './pages/dashboard/dashboard-page'
import SetupPage from './pages/setup/setup-page'

export const routes = {  
  root: '/',
  start: '/start',
  setup: '/setup',
  signin: '/signin',
  notFound: '*',
  dashboard: '/dashboard/:identityId',
}

export function Routes() {
  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route
          element={
            <Layout loggedOnly={false}>
              <LandingPage />
            </Layout>
          }
          index
        />
        <Route
          element={
            <Layout loggedOnly={false}>
              <StartPage />
            </Layout>
          }
          path={routes.start}
        />
        <Route
          element={
            <Layout loggedOnly={false}>
              <SetupPage />
            </Layout>
          }
          path={routes.setup}
        />
        <Route
          element={
            <Layout loggedOnly={false}>
              <SignInPage />
            </Layout>
          }
          path={routes.signin}
        />
        <Route
          element={
            <Layout loggedOnly={false}>
              <DashboardPage />
            </Layout>
          }
          path={routes.dashboard}
        />        
        <Route
          element={
            <Layout loggedOnly={false}>
              <NotFoundPage />
            </Layout>
          }
          path={routes.notFound}
        />
      </ReactRoutes>
    </BrowserRouter>
  )
}
